/**
 * import the global components/resources
 */
import './app.js';
/**
 * import the custom js file for this route
 */
import './../scripts/home.custom.js';
/**
 * import web components used on this route
 */

import './ai-components/ai-google-map.js';
import './ai-components/ai-map-location';
import './ai-components/ai-reviews-slider';